import { navigate, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import auth from "../../../services/auth"
import modal from "../../../services/modal"
import constants from "../../../services/constants_fhaa"
import { UploadStepContext } from "../upload-step-provider/upload-step-provider"

const ConfirmNavLink = ({
  to,
  toLocal,
  activeClassName,
  children,
  className,
  doLogout,
  onNavConfirmed,
}) => {
  const stepCtx = useContext(UploadStepContext)

  const onItemClick = (event, navigatePath) => {
    event.preventDefault()

    if (doLogout) {
      auth.logout(() => doNavigation(`/app/login`))
    } else {
      doNavigation(navigatePath)
    }
  }

  const handleUploadInProgress = () => {
    return new Promise(function (resolve, reject) {
      if (isUploadInProgress()) {
        modal.setAlertModalOpen(true, {
          title: "Any unsaved progess will be lost.",
          content: "Do you want to continue?",
          confirm: () => {
            resolve(true)
          },
          cancel: () => {
            resolve(false)
          },
          confirmLabel: "Yes",
        })
      } else {
        resolve(true)
      }
    })
  }

  const doNavigation = navigatePath => {
    handleUploadInProgress().then(function (continueNavigation) {
      if (continueNavigation) {
        if (isUploadInProgress() && navigatePath === constants.uploadPath) {
          stepCtx.setStep(0)
        } else {
          navigate(navigatePath)
        }

        if (onNavConfirmed) {
          onNavConfirmed()
        }
      }
    })
  }

  const isUploadInProgress = () => {
    if (stepCtx && stepCtx.getStep() > 0) {
      return true
    } else {
      return false
    }
  }

  if (toLocal) {
    return (
      <Link
        className={className}
        to={toLocal}
        activeClassName={activeClassName}
        onClick={e => {
          onItemClick(e, toLocal)
        }}
      >
        {children ? children : ""}
      </Link>
    )
  } else {
    return (
      <a
        className={className}
        href={to}
        onClick={e => {
          onItemClick(e, to)
        }}
      >
        {children ? children : ""}
      </a>
    )
  }
}

ConfirmNavLink.propTypes = {
  to: PropTypes.string,
  toLocal: PropTypes.string,
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  doLogout: PropTypes.bool,
  onNavConfirmed: PropTypes.func,
}

ConfirmNavLink.defaultProps = {
  doLogout: false,
}

export default ConfirmNavLink
