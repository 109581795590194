import { SelectedFilters, StateProvider } from "@appbaseio/reactivesearch"
import classNames from "classnames"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import { FaArrowLeft } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import useSuggestedKeywords from "../../../hooks/use-suggested-keywords"
import SuggestedKeywords from "../../common/suggested-keywords/suggested-keywords"
import ListMedia from "../../media-output/lists/list-media/list-media"
import MapDisplay from "../../common/map-display/map-display"
import Button from "../../common/button/button"
import mediaService from "../../../services/media"
import util from "../../../services/util"
import auth from "../../../services/auth"
import FilterFiles from "../../search/filter-files/filter-files"
import styles from "./search-page-content.module.scss"
import { FaSpinner } from "react-icons/fa"
import "font-awesome/css/font-awesome.min.css"
import axios from "axios"
import _, { filter, includes, set } from "lodash"
import { cosh } from "core-js/library/es6/number"
// import { findByTitle, getGroups } from "../../../services/group"
// import SearchAutocomplete from "../../search/filters/search-autocomplete/search-autocomplete"
// import { search } from "superagent"


const SearchPageContent = ({ location, heading }) => {
  const propsWrapper = { props: null }
  const [isOpen, toggleOpen] = useState(false)
  const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [lastMultiValue, setLastMultiValue] = useState(null)
  const [show, setShow] = useState('both')
  const [fullMedia, setFullMedia] = useState([])
  const [mixedMedia, setMixedMedia] = useState([])
  const [markers, setMarkers] = useState([])
  const [filterGroup, setFilterGroup] = useState([])
  const [resize, setResize] = useState(false)
  const [viewClass, setViewClass] = useState('mixed')
  const [loadingMarkers, setLoadingMarkers] = useState(false)
  const searchFns = {}
  let _searchState = {}
  const [activeFilters, setActiveFilters] = useState(null)
  const [lastRefreshTime, setLastRefreshTime] = useState('')
  const [group, setGroup] = useState({})
  const [searchGroup, setSearchGroup] = useState('');



  useEffect(() => {
    // mediaService.getAllMedia().then(media => {
    //   setFullMedia(media)
    //   setLoadingMarkers(false)
    // })

    if (util.isMobile()) {
      setViewClass('listFocus')
    }
  }, [])

  const setLoading = () => {
    console.log('setLaoding')
  }

  const fnSetLastRefreshDate = () => {
    setLastRefreshTime(new Date())
  }

  // const groupValue = Array.isArray(activeFilters?.searchGroup?.value) && activeFilters?.searchGroup?.value.length === 1 ? activeFilters.searchGroup.value[0] : activeFilters?.searchGroup?.value ?? undefined;
  // console.log("activeFilters", groupValue);

  // const title = groupValue;

  // useEffect(() => {
  //   if (!title) {
  //     console.log('No group value found');
  //     setMarkers(mixedMedia);
  //     return;
  //   } else {
  //     getGroups().then(allGroups => {
  //       const groupByTitle = allGroups.find(group => group.title === title);
  //       console.log('Group by title:', groupByTitle);
  //       setGroup(groupByTitle);
  //     });
  //   }
  // }, [title]);


  const setFullMap = () => {
    console.log('fullMedia:', fullMedia)
    setViewClass('mapFocus')
    setMarkers(mixedMedia)
    setResize(true)
  }

  const setMixedMap = () => {
    setViewClass('mixed')
    setMarkers(mixedMedia)
    setResize(true)
  }

  const handleSelectKeyword = async keyword => {
    // originally "searchKeywords" but we cannot use this field currently if the "searchMulti" input is active
    // -- it does a keyword search as well and you get odd results using both
    let searchFieldToUpdate = "searchMulti"

    if (searchFieldToUpdate === "searchMulti") {
      var term = ""
      if (keyword.term) {
        // keyword object
        term = keyword.term
      } else {
        // keyword string
        term = keyword.trim()
      }
      propsWrapper.props.setValue("searchMulti", term)
    } else if (searchFieldToUpdate === "searchKeywords") {
      if (keyword.term) {
        // keyword object
        finishSearchKeywordUpdate(response.data.keyword)
      } else {
        // keyword string
        // we need the entire source information as we are still using it for searching. When editing a media we just want the id & value
        await axios
          .get("/keywords/findByTerm/" + keyword.trim())
          .then(response => {
            finishSearchKeywordUpdate(response.data.keyword)
          })
      }
    }
  }

  const finishSearchKeywordUpdate = k => {
    if (k) {
      const newKeyword = { value: k.term, label: k.term, source: k }
      const updatedKeywords = []
      const curFilters = propsWrapper.props.selectedValues
      const keywords =
        curFilters.searchKeywords && curFilters.searchKeywords.value
          ? curFilters.searchKeywords.value
          : []

      let parentKeywordRepalced = false
      if (keywords && keywords.length > 0) {
        _.forEach(keywords, function (keyword) {
          // see if the new keyword is a child of this parent
          if (
            keyword.source.children &&
            keyword.source.children.split(",").filter(k => {
              return k === newKeyword.source.term
            }).length > 0
          ) {
            // if it is add the child (if a parent hasn't been already replaced) and skip adding the parent
            if (!parentKeywordRepalced) {
              updatedKeywords.push(newKeyword)
              parentKeywordRepalced = true
            }
          } else {
            updatedKeywords.push(keyword)
          }
        })
      }
      if (!parentKeywordRepalced) {
        updatedKeywords.push(newKeyword)
      }
      propsWrapper.props.setValue("searchKeywords", updatedKeywords)
    } else {
      console.log("ERROR (search-page-content): No keyword found")
    }
  }

  const onClearClick = () => {
    // this clears the elastic search query but doesn't properly clear all the inputs
    if (searchFns.clearAll) {
      searchFns.clearAll()
    }
  }






  const handleMediaNavigate = data => {
    console.log("navigating to media: ", data)
    console.log("searchState: ", _searchState)

    if (_.isEmpty(_searchState) && !!location) {
      _searchState = location.state
    }
    // this is kind of a hack. Location.state should already be holding these values but for some reason there is a disconnect between the state and the activeFilters
    // -- they only seem to match when the page first loads but then the state becomes empty after that.
    if (activeFilters) {
      console.log("handleMediaNavigate - activeFilters", activeFilters)
      _.map(activeFilters, (obj, key) => {
        if (obj.value && obj.value != null) {
          _searchState[key] = obj
        }
      })
    }
    // TODO come up with better handling than this... We need this in addition to the activeFilters mapping as it doesn't persist in there...
    if (lastMultiValue) {
      _searchState.searchMulti = lastMultiValue
    } else {
      _searchState.searchMulti = ""
    }

    navigate(`/app/detailed-file/${data.type}/${data.id}`, {
      state: {
        searchState: _searchState,
        path: window.location.pathname,
      },
    })
  }

  const fnSetData = (data) => {
    console.log("Map Data:", data);
    setMixedMedia(data);
    setMarkers(data);
  }


  const groupValue = Array.isArray(searchGroup) ? searchGroup[0] : searchGroup
  console.log('groupValueee:', groupValue)

  const listMediaFn = () => {

    const grpQuery = groupValue;
    if (grpQuery) {
      return {
        query: {
          bool: {
            must: [
              {
                match: {
                  grp: {
                    query: grpQuery,
                    operator: "and",
                  },
                },
              },
            ],
          },
        },
      };
    } else {
      return {
        query: {
          match_all: {},
        },
      }
    };
  }


  const getHeaderMarkUp = () => {
    return (
      /* this button only shows when resulution is under 768px*/
      <>
        <button
          className={styles.filterBtn}
          onClick={() => toggleOpen(v => !v)}
        >
          <svg viewBox="0 0 20 20">
            <use xlinkHref="#filter-icon" />
          </svg>
        </button>

        <div className={styles.actions}>
          <button id="searchFilterToggle" onClick={() => toggleOpen(v => !v)}>
            <span className={styles.filterIcon}>
              <svg viewBox="0 0 20 20">
                <use xlinkHref="#filter-icon" />
              </svg>
            </span>
            Filters
          </button>
        </div>
      </>
    )
  }

  const getFilterOptionsMarkUp = () => {
    return (
      <div className={classNames(styles.filter, isOpen ? "" : styles.close)}>
        <button onClick={() => toggleOpen(!isOpen)} className={styles.closeBtn}>
          <FaArrowLeft /> Close
        </button>

        <div className={styles.filterFiles}>

          <FilterFiles
            onClearHandler={onClearClick}
            availableFilters={[
              "type",
              "date",
              "location",
              "copyright",
              "source",
              "people",
              "groups",
            ]}
            lastRefreshTime={lastRefreshTime}
          />
        </div>
      </div>
    )
  }


  // TODO this could maybe be it's own component?
  const getActiveFiltersMarkup = () => {
    return (
      <div className={styles.keywords}>
        <div className={styles.selected}>
          <StateProvider
            includeKeys={["rawData"]}
            render={({ searchState }) => {
              console.log("activeFilters searchState", searchState)
              _searchState = searchState
              setMarkers(mixedMedia)
              return (
                <div className={styles.hits}>
                  {searchState &&
                    searchState.SearchResult &&
                    searchState.SearchResult.rawData &&
                    searchState.SearchResult.rawData.hits &&

                    searchState.SearchResult.rawData.hits.total &&
                    searchState.SearchResult.rawData.hits.total.relation &&
                    searchState.SearchResult.rawData.hits.total.relation ===
                    "gte" ? (
                    <>
                      <span>More Than</span>{" "}
                      {searchState.SearchResult.rawData.hits.total.value}
                    </>
                  ) : searchState &&
                    searchState.SearchResult &&
                    searchState.SearchResult.rawData &&
                    searchState.SearchResult.rawData.hits &&
                    searchState.SearchResult.rawData.hits.total &&
                    searchState.SearchResult.rawData.hits.total.value ? (
                    searchState.SearchResult.rawData.hits.total.value
                  ) : (
                    ""
                  )}
                  {propsWrapper &&
                    propsWrapper.props &&
                    propsWrapper.props.selectedValues &&
                    propsWrapper.props.selectedValues["searchKeywords"] ? (
                    <span>results for</span>
                  ) : (
                    <span>
                      {groupValue && <b>{mixedMedia.length > 0 ? mixedMedia.length : 0}</b>}
                      <span style={{ marginLeft: "5px" }}>results</span>
                    </span>
                  )}

                </div>
              )
            }}
          />
          <SelectedFilters
            showClearAll={true}
            clearAllLabel="Clear filters"

            onChange={selectedValues => {
              if (selectedValues["searchKeywords"])
                window.localStorage.setItem(
                  "searchkey",
                  selectedValues.searchMulti.value
                )

              if (selectedValues["searchGroup"]) { // Assuming 'searchGroup' is the key for the search group
                setSearchGroup(selectedValues["searchGroup"].value); // Update the search group state
              }
              setActiveFilters(selectedValues)
              if (
                selectedValues["searchMulti"] &&
                selectedValues["searchMulti"].value &&
                selectedValues["searchMulti"].value !== lastMultiValue
              ) {
                let val = selectedValues["searchMulti"].value
                setLastMultiValue(val)
                updateKeywords([{ value: val, source: { term: val } }])
              }
              if (selectedValues["searchKeywords"]) {
                setLastMultiValue(null)
                updateKeywords(selectedValues["searchKeywords"].value)
              }
            }}
            render={props => {
              propsWrapper.props = props
              searchFns.clearAll = props.clearValues
              const { selectedValues, setValue } = props

              const clearValue = component => {
                setValue(component, "")
              }

              const clearElem = (component, e) => {
                console.log(
                  "clearElem:",
                  JSON.stringify(
                    selectedValues[component].value.filter(
                      v => v.value !== e.value
                    )
                  )
                )
                setValue(
                  component,
                  selectedValues[component].value.filter(
                    v => v.value !== e.value
                  )
                )
              }

              const filters = Object.keys(selectedValues).map(component => {

                if (
                  !selectedValues[component].value ||
                  selectedValues[component].showFilter === false
                )
                  return ""
                // <p key={component}>
                //   {selectedValues[component].label}&nbsp;
                //   {JSON.stringify(selectedValues[component])}
                // </p>

                const getButtonKey = (value, comp, idx) => {
                  if (value && value.value) {
                    return value.value.toString()
                    // } else if (value) {
                    //   return value.toString()
                  } else {
                    return comp + "-" + idx
                  }
                }

                const getButtonValue = (value, comp, idx) => {
                  if (value && value.value) {
                    return value.value.toString()
                    // } else if (value) {
                    //   return value.toString()
                  } else {
                    return value.toString()
                  }
                }

                return (
                  <div className={styles.keyword} key={component}>
                    {Array.isArray(selectedValues[component].value) ? (
                      _.map(selectedValues[component].value, (v, idx) => (
                        <button
                          key={getButtonKey(v, component, idx)}
                          onClick={() => clearElem(component, v)}
                        >
                          {getButtonValue(v, component, idx)}

                          <MdClose />
                        </button>
                      ))
                    ) : selectedValues[component].value.startDate ||
                      selectedValues[component].value.endDate ? (
                      <button onClick={() => clearValue(component)}>
                        {JSON.stringify(selectedValues[component])}
                      </button>
                    ) : (
                      <button onClick={() => clearValue(component)}>
                        {selectedValues[component].value}
                      </button>
                    )}
                  </div>
                )
              })

              return filters
            }}
          />
        </div>

        <div className={styles.related}>
          <small>Related Keywords:</small>
          <SuggestedKeywords
            words={suggestedKeywords}
            add={handleSelectKeyword}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={classNames(styles.wrapper, isOpen ? styles.fixed : "")}>
        {heading ? <h2>{heading}</h2> : ""}
        {getHeaderMarkUp()}
        <div className={styles.container}>
          {getFilterOptionsMarkUp()}
          <div className={styles.medias}>
            <div className={styles.media}>
              {getActiveFiltersMarkup()}
              {loadingMarkers &&
                <div>
                  <FaSpinner className={classNames('fa-spin', styles.spinner)} />
                </div>
              }
              <div className={styles.buttonSection}>
                <div className={styles.mixedButton}>
                  <button
                    className={viewClass == 'mixed' ? styles.togglebuttonactive : styles.togglebutton}
                    id="mixed-button"
                    onClick={() => setMixedMap()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0"
                      y="0"
                      enableBackground="new 0 0 50 32"
                      version="1.1"
                      viewBox="0 0 50 32"
                      xmlSpace="preserve"
                      width="50" height="32"
                    >
                      <path
                        fill="#fff" d="M27.8 6.3c-.3-.2-.6-.2-.9-.1L21.6 8l-5.3-1.8c-.2-.1-.4-.1-.6 0L10.4 8 5.1 6.2c-.3-.1-.7-.1-.9.1-.3.2-.4.5-.4.8V23c0 .4.3.8.7 1l5.6 1.9h.4l5.3-1.8 5.3 1.8h.6l5.6-1.9c.4-.1.7-.5.7-1V7.1c.2-.3.1-.6-.2-.8zM11.4 9.7L15 8.5v13.8l-3.6 1.2V9.7zM17 8.5l3.6 1.2v13.8L17 22.3V8.5zm-11.2 0l3.6 1.2v13.8l-3.6-1.2V8.5zm20.4 13.8l-3.6 1.2V9.7l3.6-1.2v13.8z"
                        className="st0"
                      ></path>
                      <path fill="#fff" d="M32.4 6.4H35.4V9.4H32.4z" className="st0"></path>
                      <path fill="#fff" d="M32.4 11.4H35.4V14.4H32.4z" className="st0"></path>
                      <path fill="#fff" d="M32.4 16.4H35.4V19.4H32.4z" className="st0"></path>
                      <path fill="#fff" d="M32.4 21.4H35.4V24.4H32.4z" className="st0"></path>
                      <path fill="#fff" d="M37.4 6.4H40.4V9.4H37.4z" className="st0"></path>
                      <path fill="#fff" d="M37.4 11.4H40.4V14.4H37.4z" className="st0"></path>
                      <path fill="#fff" d="M37.4 16.4H40.4V19.4H37.4z" className="st0"></path>
                      <path fill="#fff" d="M37.4 21.4H40.4V24.4H37.4z" className="st0"></path>
                      <path fill="#fff" d="M42.4 6.4H45.4V9.4H42.4z" className="st0"></path>
                      <path fill="#fff" d="M42.4 11.4H45.4V14.4H42.4z" className="st0"></path>
                      <path fill="#fff" d="M42.4 16.4H45.4V19.4H42.4z" className="st0"></path>
                      <path fill="#fff" d="M42.4 21.4H45.4V24.4H42.4z" className="st0"></path>
                    </svg>
                  </button>
                </div>
                <button
                  className={viewClass == 'listFocus' ? styles.togglebuttonactive : styles.togglebutton}
                  id="tiles"
                  onClick={() => setViewClass('listFocus')}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 50 32"
                    version="1.1"
                    viewBox="0 0 50 32"
                    xmlSpace="preserve"
                    width="50" height="32"
                  >
                    <path fill="#fff" d="M21 6.4H24V9.4H21z" className="st0"></path>
                    <path fill="#fff" d="M21 11.4H24V14.4H21z" className="st0"></path>
                    <path fill="#fff" d="M21 16.4H24V19.4H21z" className="st0"></path>
                    <path fill="#fff" d="M21 21.4H24V24.4H21z" className="st0"></path>
                    <path fill="#fff" d="M16 6.4H19V9.4H16z" className="st0"></path>
                    <path fill="#fff" d="M16 11.4H19V14.4H16z" className="st0"></path>
                    <path fill="#fff" d="M16 16.4H19V19.4H16z" className="st0"></path>
                    <path fill="#fff" d="M16 21.4H19V24.4H16z" className="st0"></path>
                    <path fill="#fff" d="M26 6.4H29V9.4H26z" className="st0"></path>
                    <path fill="#fff" d="M26 11.4H29V14.4H26z" className="st0"></path>
                    <path fill="#fff" d="M26 16.4H29V19.4H26z" className="st0"></path>
                    <path fill="#fff" d="M26 21.4H29V24.4H26z" className="st0"></path>
                    <path fill="#fff" d="M31 6.4H34V9.4H31z" className="st0"></path>
                    <path fill="#fff" d="M31 11.4H34V14.4H31z" className="st0"></path>
                    <path fill="#fff" d="M31 16.4H34V19.4H31z" className="st0"></path>
                    <path fill="#fff" d="M31 21.4H34V24.4H31z" className="st0"></path>
                  </svg>
                </button>
                <button
                  className={viewClass == 'mapFocus' ? styles.togglebuttonactive : styles.togglebutton}
                  id="map-button"
                  onClick={() => setFullMap()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 50 32"
                    version="1.1"
                    viewBox="0 0 50 32"
                    xmlSpace="preserve"
                    width="50" height="32"
                  >
                    <path
                      fill="#fff" d="M37 6.4c-.3-.2-.6-.2-.9-.1l-5.3 1.8-5.3-1.8c-.2-.1-.4-.1-.6 0l-5.3 1.8-5.3-1.8c-.3-.1-.6-.1-.9.1-.3.2-.4.5-.4.8v15.9c0 .4.3.8.7 1l5.6 1.9h.4l5.3-1.8 5.3 1.8h.6l5.6-1.9c.4-.1.7-.5.7-1V7.2c.2-.3.1-.6-.2-.8zM20.6 9.8l3.6-1.2v13.8l-3.6 1.2V9.8zm5.6-1.2l3.6 1.2v13.8l-3.6-1.2V8.6zM15 8.6l3.6 1.2v13.8L15 22.4V8.6zm20.5 13.8l-3.6 1.2V9.8l3.6-1.2v13.8z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className={styles[viewClass]}>
                <div className={styles.map}>
                  {auth.isBrowser() ? <MapDisplay media={markers} resize={resize} setResize={setResize} id={'map-display'} /> : ''}
                </div>
                <div className={styles.list}>
                  <div>
                    <ListMedia
                      setData={fnSetData}
                      group={searchGroup}
                      mixedMedia={mixedMedia}
                      defaultQueryFn={listMediaFn}
                      onMediaNavigate={handleMediaNavigate}
                      onSelectKeyword={handleSelectKeyword}
                      fnSetLastRefreshDate={fnSetLastRefreshDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg display="none">
        <path
          id="filter-icon"
          d="M18.614,18.786a2.537,2.537,0,0,1-4.8,0H3.357a.857.857,0,0,1,0-1.714H13.814a2.537,2.537,0,0,1,4.8,0h1.029a.857.857,0,0,1,0,1.714Zm-3.257-.857a.857.857,0,1,0,.857-.857.81.81,0,0,0-.857.857Zm-1.457-12a2.537,2.537,0,0,1-4.8,0H3.357A.81.81,0,0,1,2.5,5.071a.81.81,0,0,1,.857-.857H9.1a2.537,2.537,0,0,1,4.8,0h5.743a.81.81,0,0,1,.857.857.81.81,0,0,1-.857.857Zm-3.257-.857a.857.857,0,1,0,1.714,0,.857.857,0,1,0-1.714,0Zm9,7.286H9.186a2.537,2.537,0,0,1-4.8,0H3.357a.857.857,0,1,1,0-1.714H4.386a2.537,2.537,0,0,1,4.8,0H19.643a.857.857,0,1,1,0,1.714ZM5.929,11.5a.81.81,0,0,0,.857.857.857.857,0,1,0,0-1.714.81.81,0,0,0-.857.857Z"
          transform="translate(-1.834 -1.5)"
        />
      </svg>
    </>
  )
}

export default SearchPageContent
