import classNames from "classnames"
import { navigate } from "gatsby"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { MdBookmark, MdOndemandVideo, MdPictureAsPdf, MdPhoto } from "react-icons/md"
import Thumbnail from "../../../common/display/thumbnail/thumbnail"
import DownloadMediaButton from "../../sections/download-media-button/download-media-button"
import MediaKeywords from "../../sections/media-keywords/media-keywords"
import styles from "./standard-card.module.scss"
import ModalWindow from "../../../common/modal-window/modal-window"
import DetailedFile from "../../../media-output/detailed-file/detailed-file"

const StandardCard = ({
  data,
  className,
  groupValue,
  onMediaNavigate,
  onSelectKeyword,
  homePage,
  fnSetMediaPlayingId,
  mediaPlayingId,
  mediaPlaying,
  fnSetMediaPlaying,
  fnSetLastRefreshDate,
  groupDisplay
}) => {

  const [modalOpen, setModalOpen] = useState(false)
  const standardCard = homePage ? false : true

  const handleItemClick = clickEvent => {
    clickEvent.preventDefault()

    setModalOpen(true)

    // if (onMediaNavigate) {
    //   onMediaNavigate(data)
    // } else {
    //   navigate(`/app/detailed-file/${data.type}/${data.id}`, {
    //     state: { id: data.id, type: data.type, href: window.location.href },
    //   })
    // }
  }

  const getModalWindow = () => {
    const onModalClose = () => {
      setModalOpen(false)

      if (!groupDisplay) {
        fnSetLastRefreshDate()
      }  
    }

    if (modalOpen) {
      return (
        <ModalWindow closeHandler={onModalClose}>
          <DetailedFile 
            id={data.id}
            type={data.type}
            location={''}
            groupDisplay={groupDisplay}
          />
        </ModalWindow>
      )
    } else {
      return ""
    }
  } 

  const getActionContent = data => {
    switch (data.type) {
      case "Video":
        return (
          <div className={styles.infoActions}>
            <DownloadMediaButton mediaId={data.fileId} name={data.fileName} />
          </div>
        )

      case "Document":
        return (
          <div className={styles.infoActions}>
            <DownloadMediaButton mediaId={data.fileId} name={data.fileName} />
          </div>
        )
      case "Photo":
        return (
          <div className={styles.infoActions}>
            <DownloadMediaButton mediaId={data.fileId} name={data.fileName} />
          </div>
        )
      case "Audio":
      case "Archive":
        return (
          <div className={styles.infoActions}>
            <DownloadMediaButton mediaId={data.fileId} name={data.fileName} />
          </div>
        )
      default:
        return
    }
  }

  const getDateValue = () => {
    if (data.date && data.date !== "18700101") {
      if (data.date.length === 6) {
        return moment(data.date, "YYYYMM")
          .format("MMM-YYYY")
          .toString()
      } else if (data.date.length === 8) {
        return moment(data.date, "YYYYMMDD")
          .format("DD-MMM-YYYY")
          .toString()
      }
    } else {
      return ""
    }
  }

  const getDurationDisplay = data => {
    switch (data.type) {
      case "Video":
        return (
          <span className={classNames(styles.duration, styles.videoDuration)}>
            <svg viewBox="0 0 26 26">
              <path
                d="M21.7,15.519,14.018,9.935a.926.926,0,0,0-1.471.747V21.846a.923.923,0,0,0,.506.826.933.933,0,0,0,.421.1.921.921,0,0,0,.544-.18L21.7,17.013a.919.919,0,0,0,0-1.494Z"
                transform="translate(-3.93 -3.603)"
              />
              <path
                d="M12.282,0A12.28,12.28,0,1,0,24.563,12.284,12.28,12.28,0,0,0,12.282,0Zm0,22.51A10.23,10.23,0,1,1,22.51,12.284,10.229,10.229,0,0,1,12.282,22.512Z"
                transform="translate(0 -0.002)"
              />
            </svg>
            2:35
          </span>
        )
      case "Audio":
        return (
          <span className={classNames(styles.duration, styles.audioDuration)}>
            <svg viewBox="0 0 26 26">
              <path
                d="M21.7,15.519,14.018,9.935a.926.926,0,0,0-1.471.747V21.846a.923.923,0,0,0,.506.826.933.933,0,0,0,.421.1.921.921,0,0,0,.544-.18L21.7,17.013a.919.919,0,0,0,0-1.494Z"
                transform="translate(-3.93 -3.603)"
              />
              <path
                d="M12.282,0A12.28,12.28,0,1,0,24.563,12.284,12.28,12.28,0,0,0,12.282,0Zm0,22.51A10.23,10.23,0,1,1,22.51,12.284,10.229,10.229,0,0,1,12.282,22.512Z"
                transform="translate(0 -0.002)"
              />
            </svg>
            1:28
          </span>
        )
      case "Photo":
      case "Document":
      case "Archive":
      case "Story":
      default:
        return
    }
  }

  if (data && data.type) {
    return (
      <div
        id={"card" + data._id}
        className={classNames(styles.wrapper, className)}
        key={data._id}
      >
        {getModalWindow()}
        <div className={styles.thumbnailWrap}>
          <div className={styles.anchor}>
            <div
              id={"cardThumbnailWrapper" + data._id}
              className={styles.thumbnailMask}
            >
              <Thumbnail 
                data={data} 
                standardCard={standardCard} 
                mediaPlayingId={mediaPlayingId} 
                fnSetMediaPlayingId={fnSetMediaPlayingId}
                mediaPlaying={mediaPlaying}
                fnSetMediaPlaying={fnSetMediaPlaying}
              />
            </div>
            {/* We don't have this data parsed/saved yet... Can re-add if we get it */}
            {/* {getDurationDisplay(data)} */}
          </div>
        </div>
          <div className={styles.infoWrap}>
            <div className={styles.infoTop}>
              {!homePage &&
                <MediaKeywords
                  format="compact"
                  keywords={data.keywords ? data.keywords : ""}
                  mediaId={data._id}
                  onSelectKeyword={onSelectKeyword}
                />
              }
            </div>
            <div className={styles.infoBottom} onClick={handleItemClick}>
              <div className={styles.infoContent}>
                <div id={"cardTitle" + data._id} className={styles.title}>
                  {data.title}
                </div>
                <div className={styles.infoLower}>
                  <div id={"cardPhotoDate" + data._id} className={styles.date}>
                    {getDateValue()}
                  </div>
                  <div id={"cardSize" + data._id} className={styles.size}>
                    {data.size ? `Size: ${(data.size/1000000).toFixed(2)} Mb` : 'Size: NA'}
                  </div>
                </div>  
              </div>
              {!groupDisplay &&
                <div id={"cardActions" + data._id} className={styles.infoActions}>
                  {getActionContent(data)}
                </div>
              }
            </div>
          </div>
      </div>
    )
  } else {
    return <div className={styles.wrapper}>Invalid Data</div>
  }
}

StandardCard.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  onMediaNavigate: PropTypes.func,
  onSelectKeyword: PropTypes.func,
}

export default StandardCard
