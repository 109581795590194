import PropTypes from "prop-types"
import React from "react"
import { splitFormProps, useField } from "react-form"
import * as ReactIcon from "react-icons/md"
import { useState } from "react"
import styles from "./text-field.module.scss"
import classNames from "classnames"
import Label from "../label/label"
import ErrorMessage from "../error-message/error-message"

const TextField = React.forwardRef((props, ref) => {
  const [isFocus, setFocus] = useState(false)

  // Let's use splitFormProps to get form-specific props
  const [
    field,
    fieldOptions,
    {
      label,
      required,
      mandatory,
      description,
      prependIcon,
      appendIcon,
      errorMessage,
      hideLabel,
      id,
      trailingIcon,
      ...rest
    },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    meta: { error, isTouched, isValidating },
    getInputProps,
  } = useField(field, fieldOptions)

  // Build the field
  return (
    <div className={styles.wrapper}>
      {hideLabel ? ("") : (<Label label={label} mandatory={mandatory} description={description} />)}
      <div
        className={classNames(
          styles.inputWrap,
          isFocus ? "" + styles.active : "",
          hideLabel ? "" + styles.noLabel : ""
        )}
      >
        {prependIcon ? (
          <div className={classNames(styles.icon, styles.prependIcon)}>
            {React.createElement(ReactIcon[prependIcon])}
          </div>
        ) : (
          ""
        )}
        <input
          id={id ? id : field}
          {...getInputProps({ ref, required, ...rest })}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {trailingIcon && (
          <div className={styles.trailingIcon}>
            {trailingIcon}
          </div>
        )}
        {appendIcon ? (
          <div className={classNames(styles.icon, styles.appendIcon)}>
            {React.createElement(ReactIcon[appendIcon])}
          </div>
        ) : (
          ""
        )}
        {isValidating ? (
          <em>Validating...</em>
        ) : isTouched && error ? (
          <div
            className={classNames(styles.icon, styles.errorIcon)}
            data-tip={error}
            data-event="click focus"
          >
            {React.createElement(ReactIcon["MdError"])}
          </div>
        ) : ""}
      </div>
      {error ? <ErrorMessage errorMessage={error} /> : ""}
    </div>
  )
})

TextField.defaultProps = {
  required: false,
  type: "text",
}

TextField.propTypes = {
  appendIcon: PropTypes.string,
  prependIcon: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  field: PropTypes.string,
  type: PropTypes.string,
  mandatory: PropTypes.bool,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  trailingIcon: PropTypes.element,
}

export default TextField
