import classNames from "classnames"
import { convertFromRaw, convertToRaw } from "draft-js"
import _ from "lodash"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-form"
import { MdCheckCircle, MdHelpOutline } from "react-icons/md"
import ReactTooltip from "react-tooltip"
import useSuggestedKeywords from "../../../hooks/use-suggested-keywords"
import mediaService from "../../../services/media"
import keywordService from "../../../services/keyword"
import ModalWindow from "../../common/modal-window/modal-window"
import MediaMapping from "../../media-mapping/media-mapping"
import Button from "../../common/button/button"
import StoryEditor from "../../common/form/story-editor/story-editor"
import TextArea from "../../common/form/text-area/text-area"
import TextField from "../../common/form/text-field/text-field"
import GenericMessage from "../../common/generic-message/generic-message"
import SuggestedKeywords from "../../common/suggested-keywords/suggested-keywords"
import AutocompleteTags from "../../search/inputs/autocomplete-tags/autocomplete-tags"
import Autocomplete from "../../search/inputs/autocomplete/autocomplete"
import styles from "./story-details-edit.module.scss"

const StoryDetailsEdit = ({
  item,
  setEditStateFn,
  itemTags,
  message,
  messageType,
  ...props
}) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [suggestedKeywords, updateKeywords] = useSuggestedKeywords()
  const [publishing, setPublishing] = useState(false)
  const [_messageType, setMessageType] = useState(messageType)
  const [_message, setMessage] = useState(message)
  const [mapModal, setMapModal] = useState(false)
  const [geo, setGeo] = useState()
  const [location, setLocation] = useState(item.location)

  useEffect(() => {
    setMessage(message)
    setMessageType(messageType)
  }, [message, messageType])

  useEffect(() => {
    if (item["geo"]) {
      setGeo(item["geo"])
    }
    else {
      setGeo({ lat: 55.000000, lon: -115.000000 })
    }
  }, [])


  // store a reference of the keywords on the item as "tag" objects.
  item.tags = itemTags
  !item.description ? (item.description = "") : ""
  if (item.content) {
    item.editContent = convertFromRaw(JSON.parse(item.content))
  } else {
    item.editContent = null
  }

  const defaultValues = React.useMemo(() => item, [])

  const formInstance = useForm({
    defaultValues: defaultValues,
    debugForm: false,
    onSubmit: values => {
      var updatedObj = null
      // if (meta.isTouched && meta.isValid) {
      let lat = 0
      let lon = 0
      if (geo.lat !== '') {
        lat = geo.lat
        lon = geo.lon
      }


      let saveLocation = ''
      if (geo.name) {
        saveLocation = geo.name
      }
      else if (geo.name === '') {
        saveLocation = geo.name
      }

      updatedObj = {
        ...values,
        media: item.media,
        content: JSON.stringify(convertToRaw(values.editContent)),
        keywords: keywordService.getKeywordsForSave([values], ["tags"]),
        location: saveLocation,
        geo: saveLocation !== '' ? { lat, lon } : null,
        permissions: "PUBLIC",
        copyright: "ANYONE",
        // }
      }

      console.log('Obj:', updatedObj)
      setEditStateFn(false, updatedObj, false, publishing)
    },
    validate: (values, instance) => {
      setSubmitAttempted(false)
      if (!values.editContent) {
        return true // "Story content is required."
      } else if (!values.title) {
        return true // "Title is required"
      } else if (!values.tags || values.tags.length === 0) {
        // we look at "tags" as that is the current tags from editing. "keywords" gets updated during submit
        return true // "At least one keyword is required"
      }

      return false
    },
  })

  const { Form, meta, pushFieldValue } = formInstance

  const onMapClick = () => {
    setMapModal(true)
  }

  const setLocationCoordinates = (location) => {

    const obj = {
      name: location.name,
      lat: location.geo.lat,
      lon: location.geo.lon
    }

    setGeo(obj)
    setLocation(location.name)
  }

  const getMapModal = () => {
    const onMapClose = () => {
      setMapModal(false)
    }

    if (mapModal) {
      return (
        <ModalWindow title="Map Position" closeHandler={onMapClose}>
          <MediaMapping
            setLocation={setLocationCoordinates}
            setMapModal={setMapModal}
            onMapClose={onMapClose}
            location={location}
            geo={geo}
          />
        </ModalWindow>
      )
    }
  }

  const onClearClick = () => {
    setGeo({ lat: 55.000000, lon: -115.000000 })
    setLocation('')
  }

  const getLocation = updatedObj => {
    if (updatedObj.location == null || _.isString(updatedObj.location))
      return updatedObj.location
    else {
      return updatedObj.location.value
    }
  }

  const addSuggested = k => {
    const newKeyword = { value: k.term, source: { _id: k._id } }
    pushFieldValue("tags", newKeyword)
  }

  const onSaveClick = () => {
    setPublishing(false)
    setSubmitAttempted(true)
  }

  const isCreateMode = () => {
    var id = item._id ? item._id : item.id
    return id == null
  }

  const onCancelClick = () => {
    if (!isCreateMode()) {
      setEditStateFn(false)
    }
  }

  const getStoryStatus = () => {
    var status = "Published"
    if (item.status === "DRAFT" || !item.status) {
      status = "Unpublished"
    }
    return (
      <div
        className={classNames(
          styles.status,
          item.status === "DRAFT" || !item.status ? styles.draft : ""
        )}
      >
        <MdCheckCircle /> {status}
      </div>
    )
  }
  const getEditedDate = () => {
    var date = item.updatedAt ? item.updatedAt : item.createdAt
    if (date) {
      return (
        <div className={styles.lastUpdate}>
          Last edited:
          {moment(date)
            .format("MMM DD, YYYY")
            .toString()}
        </div>
      )
    } else {
      return ""
    }
  }

  const onPublishClick = () => {
    setPublishing(true)
    setSubmitAttempted(true)
  }

  const getActions = () => {
    return (
      <div className={styles.actions}>
        <Button
          id="create-story-publish"
          type="submit"
          label="Publish"
          btnStyle="primary"
          btnSize="large"
          disableStatus={!isCreateMode()}
          fullWidth
          onClick={onPublishClick}
        />
        <div className={styles.subButton}>
          <Button
            id="save-story"
            label="Save Draft"
            type="submit"
            btnStyle="secondary"
            btnSize="medium"
            // disableStatus={isCreateMode()}
            fullWidth
            prependIcon="MdSave"
            onClick={onSaveClick}
          />
          <Button
            id="cancel-story"
            label="Cancel"
            btnStyle="secondary"
            btnSize="medium"
            fullWidth
            prependIcon="MdCancel"
            onClick={onCancelClick}
            disableStatus={isCreateMode()}
          />
        </div>
      </div>
    )
  }

  const onAttachMediaSuccess = attachedMedia => {
    item.media
      ? item.media.push(attachedMedia.id)
      : (item.media = [attachedMedia.id])
  }

  const onRemoveMediaSuccess = removedMediaId => {
    let filtered = item.media.filter(mediaObj => {
      if (mediaObj.id) {
        return mediaObj.id !== removedMediaId
      } else {
        return mediaObj !== removedMediaId
      }
    })
    item.media = filtered
  }

  const getKeywords = () => {
    // ReactTooltip.rebuild()

    return (
      <li>
        <AutocompleteTags
          index="keyword"

          dataField="term"
          field="tags"
          label="Keywords"
          defaultValue={[]}
          validate={tags => {
            updateKeywords(tags)
            return !tags || !tags.length ? "This is required!" : false
          }}
          mandatory
          errorMessage={submitAttempted ? meta.error : ""}
        />
        <div className={styles.SuggestedKeywords}>
          Suggested Keywords
          <span
            className={styles.helpIcon}
            data-tip="Adding additional keywords will help others find your image during searches."
            data-event="click focus"
          >
            <a onClick={e => e.preventDefault()}>
              <MdHelpOutline />
            </a>
          </span>
        </div>
        <SuggestedKeywords words={suggestedKeywords} add={addSuggested} />
      </li>
    )
  }

  return (
    <Form className={styles.wrapper}>
      <h2>{isCreateMode() ? "Create Story" : "Edit Story"}</h2>
      <GenericMessage
        message={_message}
        type={_messageType ? messageType : "success"}
        closeIcon
        setTime
      />
      <div className={styles.container}>
        <div className={styles.detailLeft}>
          <div className={styles.form1}>
            <TextField
              label="Title"
              field="title"
              type="text"
              mandatory
              validate={value => {
                return !value ? "This is required!" : false
              }}
            />
            <StoryEditor
              suppressError={!submitAttempted}
              attachMediaHandler={onAttachMediaSuccess}
              removeMediaHandler={onRemoveMediaSuccess}
              field="editContent"
              mandatory
              validate={value => {
                let valid = true
                if (!value) {
                  valid = false
                } else if (
                  value &&
                  value.getPlainText &&
                  value.getPlainText() == ""
                ) {
                  valid = false
                }
                return !valid ? "This is required!" : false
              }}
            />
          </div>
        </div>
        <div className={styles.detailRight}>
          <div className={styles.statusGroup}>
            {getEditedDate()}
            <div className={styles.lastUpdate}>
              {/* NOTE:this is just a static text for display date's style before getEditedDate working */}
              {/* Last edited: 11/26/2019 */}
            </div>
            {getStoryStatus()}
          </div>
          <div className={styles.actionGroup}>{getActions()}</div>
          <div className={styles.metadataGroup}>
            <ul className={styles.metaData}>
              {/* <li>
                <Autocomplete
                  label="Location"
                  index="location"
                  field="location"
                  curText={item["location"]}
                  dataField="name"
                  icon="MdPlace"
                  allowMultiple={false}
                  mandatory
                  validate={value => {
                    return !value || value.value == ""
                      ? "This is required!"
                      : false
                  }}
                />
              </li> */}
              <li>
                <div className={styles.locationSection}>
                  <div className={styles.locationItem}>
                    {'Location: '}
                    <b>{location ? location : 'No Location'}</b>
                  </div>
                  {/* <TextField
                    label="Location"
                    field="location"
                    type="text"
                    defaultValue={location ? location : formInstance.values.location}
                    // disabled
                  /> */}
                  <div className={styles.locationItem}>
                    <Button
                      id="map-button"
                      type="button"
                      btnStyle="primary"
                      btnSize="small"
                      prependIcon="MdGpsFixed"
                      onClick={onMapClick}
                    />
                  </div>
                  <div className={styles.locationItem}>
                    <Button
                      id="clear-button"
                      type="button"
                      btnStyle="primary"
                      btnSize="small"
                      prependIcon="MdDeleteForever"
                      onClick={onClearClick}
                    />
                  </div>
                </div>
              </li>
              {getMapModal()}
              <br />
              <li>
                <TextArea label="Note" field="description" />
              </li>
              <li>
                <TextField label="People" field="people" type="text" />
              </li>
              {getKeywords()}
            </ul>
          </div>
        </div>
      </div>
    </Form>
  )
}

StoryDetailsEdit.defaultProps = {
  itemTags: [],
}

StoryDetailsEdit.propTypes = {
  item: PropTypes.object.isRequired,
  itemTags: PropTypes.array,
  message: PropTypes.string,
  setEditStateFn: PropTypes.func.isRequired,
}

export default StoryDetailsEdit
